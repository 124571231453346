

@tailwind base;
@tailwind components;
@tailwind utilities;


$pb-cyan: #1dd7ff;
$pb-contrast: #2B8EA3;

$pb-green: #8EE44A;
$pb-green-contrast: #508c22;


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}

body {
  max-height: 100vh;
  overflow: hidden;
  font-family: 'f04b',
}

.btn {
  position: relative;
  border-radius: 12.75px;
  background: $pb-contrast;
  display: block;
  border: 0;
  margin-top: 50px;
  width: 300px;
  height: 90px;
}

.btn::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: $pb-contrast;
  border-radius: inherit;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
}

.btnWrapper {
  position: relative;
  z-index: 3;
  display: block;
  width: 300px;
  height: 90px;
  background: $pb-cyan;
  border-radius: inherit;
  position: relative;
  border: 3px solid $pb-contrast;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-3.75px, -6px);
  transition: transform 0.3s ease 0.1s;
}

.btnSecondary {
  position: relative;
  border-radius: 12.75px;
  background: $pb-green-contrast;
  display: block;
  border: 0;
  margin-top: 50px;
  width: 300px;
  height: 90px;
}

.btnSecondary::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: $pb-green-contrast;
  border-radius: inherit;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
}

.btnWrapperSecondary {
  position: relative;
  z-index: 3;
  display: block;
  width: 300px;
  height: 90px;
  background: $pb-green;
  border-radius: inherit;
  position: relative;
  border: 3px solid $pb-green-contrast;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-3.75px, -6px);
  transition: transform 0.3s ease 0.1s;
}

.btnText {
  position: relative;
  z-index: 2;
  color: #fff;
}

.btnGlass {
  width: 90px;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
  z-index: 1;
  transition: left 0.45s ease;
}


.btn {
  position: relative;
  border-radius: 12.75px;
}

.btn:hover .btnWrapper {
  transform: translate(0);
}
.btn:hover .btnWrapper .btnGlass {
  left: 50%;
}